import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./NotFound.template.vue";

@Component({
  mixins: [Template]
})
export default class ReportsComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public back() {
    this.$router.back();
  }

  public created() {
    this.setPageNav(null);
  }
}
